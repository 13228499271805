<div *ngIf="!node.children">
  <a *ngIf="!node.divider" href="{{node.url}}" [ngClass]="classes" title="{{node.tooltip}}"
    class="vertical-menu-item" style="position: relative" [target]="isExternalUrl(node.url)?'_blank':'_self'">
    {{node.title}}
    <mat-icon *ngIf="isExternalUrl(node.url)">open_in_new</mat-icon>
  </a>
  <div *ngIf="node.divider" class="mat-divider" style="margin: 4px 20px; border-top: 1px solid lightgray"></div>
</div>

<div *ngIf="node.children">
  <a *ngIf="node.url != null" href="{{node.url}}" [ngClass]="classes" title="{{node.tooltip}}"
    (click)="headerClicked()" class="vertical-menu-item heading">
    {{node.title}}
    <mat-icon class="rotating-icon" svgIcon="keyboard_arrow_right"></mat-icon>
  </a>

  <button *ngIf="node.url == null" type="button" [ngClass]="classes" title="{{node.tooltip}}"
    (click)="headerClicked()" class="vertical-menu-item heading"
    [attr.aria-pressed]="isExpanded">
    {{node.title}}
    <mat-icon class="rotating-icon" svgIcon="keyboard_arrow_right"></mat-icon>
  </button>

  <div class="heading-children" [ngClass]="classes">
    <aio-nav-item *ngFor="let node of nodeChildren" [level]="level + 1" [isWide]="isWide"
    [isParentExpanded]="isExpanded"
    [node]="node" [selectedNodes]="selectedNodes"></aio-nav-item>
  </div>
</div>
